import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatCenters = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatTpvRequests = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatTpvs = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatRoles = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatAdvertisements = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatRequests = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatDepartments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatConfigurations = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatOrders = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatArticles = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name} - ${data.reference}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name} - ${data.reference}`,
		};
	}
};
export const formatEvents = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatWorkOrders = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			ITV: data.ITV && moment(data.ITV),
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			ITV: data.ITV && moment(data.ITV),
			date: data.date && moment(data.date),
		};
	}
};
export const formatMaintenances = (data, state) => {
	if (!data) {
		return [];
	}

	const formatLabel = (item) => {
		const date = moment(item.date).format("LL");
		if (!state || !state.vehicles || !state.vehicles[item.vehicle]) {
			return date;
		}
		return `${date} - ${state.vehicles[item.vehicle].licensePlate}`;
	};

	if (isArray(data)) {
		return data.map((item) => ({
			...item,
			label: formatLabel(item),
			value: item._id,
			date: item.date && moment(item.date),
		}));
	}

	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: formatLabel(data),
			date: data.date && moment(data.date),
		};
	}

	return [];
};
export const formatHolidays = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatPetitions = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatSingUps = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatEquipments = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatShifts = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatPermisions = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatAbsences = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatEpiRequests = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
		};
	}
};
export const formatEpis = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.equipment + "-" + data.marc + "-" + data.model}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.equipment + "-" + data.marc + "-" + data.model}`,
		};
	}
};
export const formatExpenses = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
			start: data.start && moment(data.start),
			end: data.end && moment(data.end),
		};
	}
};
export const formatRoatSheets = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
			date: data.date && moment(data.date),
			startHour: data.startHour && moment(data.startHour),
			finishHour: data.finishHour && moment(data.finishHour),
			deliberySheet: data.deliberySheet && data.deliberySheet.map((data) => ({ ...data, arrival_time: moment(data.arrival_time) })),
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
			date: data.date && moment(data.date),
			startHour: data.startHour && moment(data.startHour),
			finishHour: data.finishHour && moment(data.finishHour),
			deliberySheet: data.deliberySheet && data.deliberySheet.map((data) => ({ ...data, arrival_time: moment(data.arrival_time) })),
		};
	}
};
export const formatRoads = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatCustomers = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name}`,
		};
	}
};
export const formatVehicles = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.licensePlate}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.licensePlate}`,
		};
	}
};
export const formatUsers = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.name} ${data.surname}`,
			value: data._id,
			holidaysPerYear: data.holidaysPerYear
				? data.holidaysPerYear.map((holiday) => ({
						year: moment(holiday.year),
						days: holiday.days,
				  }))
				: [],
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.name} ${data.surname}`,
			holidaysPerYear: data.holidaysPerYear
				? data.holidaysPerYear.map((holiday) => ({
						year: moment(holiday.year),
						days: holiday.days,
				  }))
				: [],
		};
	}
};

export const formatAlerts = (data) => {
	if (!data) {
		return [];
	}
	if (isArray(data)) {
		return data.map((data) => ({
			...data,
			label: `${data.title}`,
			value: data._id,
		}));
	}
	if (isObject(data)) {
		return {
			...data,
			value: data._id,
			label: `${data.title}`,
		};
	}
};
